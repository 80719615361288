<template>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Editar cliente</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn class="ml-3" @click="$emit('update:dialogCustomer', false), clear()" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
        <!-- <v-skeleton-loader v-if="$store.state.customers.retrieveLoader" class="document-skeleton rounded-md" v-bind="attrs" type="list-item-three-line" />
        <template v-else> -->
          <v-row class="px-5" no-gutters>
            <v-col cols="3" class="pa-0">
              <span class="grey-700--text body-1">{{$t('generals.RUT')}}</span>
              <v-text-field v-model.trim="$v.editable.taxpayer.tax_id.$model" :error="$v.editable.taxpayer.tax_id.$error" readonly disabled v-dni="editable.country" outlined single-line dense hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">Nombre de la organización</span>
              <v-text-field v-model.trim="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line dense maxlength="100" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 py-3" no-gutters>
            <v-col cols="5" class="pa-0">
              <span class="grey-700--text body-1">Actividad económica</span>
              <v-text-field v-model.trim="$v.editable.business_activity.$model" :error="$v.editable.business_activity.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">Email de emisión</span>
              <v-text-field v-model.trim="$v.editable.email.$model" :error="$v.editable.email.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 pb-0" no-gutters>
            <v-col cols="8" class="pa-0">
              <span class="grey-700--text body-1">Dirección</span>
              <v-text-field v-model.trim="$v.editable.address.$model" :error="$v.editable.address.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">{{$t('generals.Comuna')}}</span>
              <v-autocomplete v-model="$v.editable.state.$model" :error="$v.editable.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 grey-700--text font-weight-medium">
              Actividad económica adicional
              <v-chip class="ml-2 pa-2" x-small color="grey-background">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in additionalTaxSettings" :key="i">
              <v-row class="py-1" align="center" no-gutters>
                <v-col cols="8">
                  <span class="grey-700--text body-1">Actividad económica</span>
                  <v-text-field v-model.trim="d.description" outlined required single-line dense maxlength="64" hide-details />
                </v-col>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-4 ml-3" v-if="i >= 1 || additionalTaxSettings.length > 1" @click="handlerDelete(d.id, 'business_activities', 'modal'), additionalTaxSettings.splice(i, 1)" text color="grey-500" :ripple="false"><v-icon color="blue-500">mdi-delete</v-icon></v-btn>
                  </div>
              </v-row>
            </div>
            <v-btn class="mt-1" v-if="additionalTaxSettings.length < 10" @click.prevent="addRowAdditionalTaxSettings" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otra actividad económica</span>
            </v-btn>
          </div>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 grey-700--text font-weight-medium">
              Dirección
              <v-chip class="ml-2 pa-2" x-small color="grey-background">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in additionalAddress" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-7 pa-0">
                  <span class="grey-700--text body-1">Dirección</span>
                  <v-text-field v-model="$v.additionalAddress.$each[i].address.$model" :error="$v.additionalAddress.$each[i].address.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1" style="max-width: 360px;">
                  <span class="grey-700--text body-1">{{$t('generals.Comuna')}}</span>
                  <v-select v-model="d.state" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                    <template v-slot:item="data">
                      <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                    </template>
                  </v-select>
                </div>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-4" v-if="i >= 1 || additionalAddress.length > 1" @click="handlerDelete(d.id, 'extra_addresses', 'modal'), additionalAddress.splice(i, 1)" text color="grey-500" :ripple="false"><v-icon color="blue-500">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn class="mt-1" v-if="additionalAddress.length < 10" @click.prevent="addRowAdditionalAddress" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otra dirección</span>
            </v-btn>
          </div>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 grey-700--text font-weight-medium">
              Contactos
              <v-chip class="ml-2 pa-2" x-small color="grey-background">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in contacts" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-4 pa-0">
                  <span class="grey-700--text body-1">Nombre</span>
                  <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1">
                  <span class="grey-700--text body-1">Email</span>
                  <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-3 pa-0" style="max-width: 290px;">
                  <span class="grey-700--text body-1">Teléfono</span>
                  <v-text-field v-model.trim="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                </div>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-5" v-if="i >= 1 || contacts.length > 1" @click="handlerDelete(d.id, 'contacts', 'modal'), contacts.splice(i, 1)" text color="grey-500" :ripple="false"><v-icon color="blue-500">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn class="mt-1" v-if="contacts.length < 10" @click.prevent="addRowContacts" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otro</span>
            </v-btn>
          </div>
        <!-- </template> -->
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-5 py-2">
        <v-spacer/>
        <v-btn outlined @click="$emit('update:dialogCustomer', false), clear()">Cancelar</v-btn>
        <v-btn
          color="blue-500"
          :loading="$store.state.customers.createLoader"
          @click="patchCustomer()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </template>

  <script>
  import { mapState } from 'vuex'
  import cloneDeep from 'lodash/cloneDeep'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import { required, email, url, maxLength } from 'vuelidate/lib/validators'

  export default {
    mixins: [
        GenericViewMixin
      ],
    props: {
      // editable: {
      //   type: Object,
      //   default: () => ({})
      // },
      customer: {
        type: Object,
        default: () => ({})
      },
      dialogCustomer: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      editable: {},
      additionalTaxSettings: [{ description: '' }],
      additionalAddress: [{ address: '', state: '' }],
      contacts: [{ name: '', email: '', phone: '' }],
      currentActionDestroy: {
          id: null,
          key: '',
          type: ''
        },
      additionalTaxSettingsLoading: false,
      additionalAddressLoading: false,
      contactsLoading: false,
      additionalTaxSettingsDeleteList: [],
      contactsDeleteList: [],
      additionalAddressDeleteList: []
  }),
    computed: {
      ...mapState({
        statesList: state => state.base.statesList,
        instance: state => state.customers.customersDetail,
        count: state => state.documents.documentsCount,
        documentsList: state => state.documents.documentsList,
        business_activitiesList: state => state.customers.business_activitiesList,
        extra_addressesList: state => state.customers.extra_addressesList,
        contactsList: state => state.customers.contactsList
      }),
      nameState () {
        return this.$store.getters['base/currentStates']?.find(state => state.id === this.editable.state)?.name ?? ''
      },
      nameStateList () {
        return (item = '') => {
          return this.$store.getters['base/currentStates']?.find(state => (state.id === item))?.name ?? ''
        }
      }
    },
    created () {
      this.editable = cloneDeep({
        ...this.customer,
        taxpayer: {
          tax_id: this.customer.taxpayer?.tax_id ?? this.customer.taxpayer
        }
      })
      if (this.dialogCustomer) this.handlerEdit('isEdit')
    },
    methods: {
      handlerEdit () {
        this.getContacts('isEdit')
        this.getExtraAddress('isEdit')
        this.getBusinessActivities('isEdit')
      },
      getContacts (action = '') {
        this.contactsLoading = action !== 'isEdit'
        this.$store.dispatch('customers/LIST', {
          resource: 'customers/contacts',
           query: {
            customer: this.editable?.id ?? this.$route.params?.id
          }
        })
        .then(() => {
          if (this.contactsList.length) {
            this.contacts = cloneDeep(this.contactsList)
          }
        })
        .finally(() => {
          this.contactsLoading = action !== 'isEdit' && !this.contactsLoading
        })
      },
      getExtraAddress (action = '') {
        this.additionalAddressLoading = action !== 'isEdit'
        this.$store.dispatch('customers/LIST', {
          resource: 'customers/extra_addresses',
           query: {
            customer: this.editable?.id ?? this.$route.params?.id
          }
        })
        .then(() => {
          if (this.extra_addressesList.length) {
            this.additionalAddress = cloneDeep(this.extra_addressesList)
          }
        })
        .finally(() => {
          this.additionalAddressLoading = action !== 'isEdit' && !this.additionalAddressLoading
        })
      },
      clear () {
        // this.editable = {}
        // this.additionalTaxSettings = [{ description: '' }]
        // this.additionalAddress = [{ address: '', state: '' }]
        // this.contacts = [{ name: '', email: '', phone: '' }]
        // this.currentActionDestroy = {
        //   id: null,
        //   key: '',
        //   type: ''
        // }
      },
      getBusinessActivities (action = '') {
        this.additionalTaxSettingsLoading = action !== 'isEdit'
        this.$store.dispatch('customers/LIST', {
          resource: 'customers/business_activities',
          query: {
            customer: this.editable?.id ?? this.$route.params?.id
          }
        })
        .then(() => {
          if (this.business_activitiesList.length) {
            this.additionalTaxSettings = cloneDeep(this.business_activitiesList)
          }
        })
        .finally(() => {
          this.additionalTaxSettingsLoading = action !== 'isEdit' && !this.additionalTaxSettingsLoading
        })
      },
      addRowAdditionalTaxSettings () {
        this.additionalTaxSettings.push({
          cl_activity_description: ''
        })
      },
      addRowAdditionalAddress () {
        this.additionalAddress.push({
          address: '',
          state: ''
        })
      },
      addRowContacts () {
        this.contacts.push({
          name: '',
          email: '',
          phone: ''
        })
      },
      async getList () {
        this.loading = true
        await this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            customer: this.instance.id,
            page_size: 6
          }
        })
        this.loading = false
      },
      handlerDelete (id = null, key = '', type = '') {
        this.currentActionDestroy = { id, key, type }
        if (type !== 'modal') this.dialogDelete = true
        else this.destroy()
      },
      destroy () {
        let resource = {}
        if (this.currentActionDestroy.key === 'contacts') resource = 'contacts'
        if (this.currentActionDestroy.key === 'extra_addresses') resource = 'extra_addresses'
        if (this.currentActionDestroy.key === 'business_activities') resource = 'business_activities'

        this.$store.dispatch('customers/DESTROY', {
          resource: `customers/${resource}`,
          id: this.currentActionDestroy.id
        })
        .then(() => {
          if (this.currentActionDestroy.type !== 'modal') {
            if (this.currentActionDestroy.key === 'contacts') this.getContacts()
            if (this.currentActionDestroy.key === 'extra_addresses') this.getExtraAddress()
            if (this.currentActionDestroy.key === 'business_activities') this.getBusinessActivities()

            this.$dialog.message.info(this.msgDelete[this.currentActionDestroy.key])
          }
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data.detail))
        })
        .finally(() => {
          this.dialogDelete = false
          this.confirm = ''
        })
      },
      patchCustomer () {
        this.$v.editable.$touch()
        if (this.$v.editable.$invalid) return false

        if (this.additionalAddress[0]?.state?.length) {
          this.$v.additionalAddress.$touch()
          if (this.$v.additionalAddress.$invalid) return false
        }
        if (this.contacts[0]?.phone?.length || this.contacts[0]?.name?.length) {
          this.$v.contacts.$touch()
          if (this.$v.contacts.$invalid) return false
        }

        const payload = {
          name: this.editable.name,
          state: this.editable.state,
          address: this.editable.address,
          tax_settings: this.editable.business_activity,
          email: this.editable.email,
          phone: this.editable.phone,
          website: this.editable.website
        }
        // if (!this.instance.country.has_regulation) {
        //   payload.tax_id = this.editable.taxpayer.tax_id
        // }
        this.$store.dispatch('customers/UPDATE', {
          resource: 'customers',
          id: this.editable?.id ?? this.$route.params?.id,
          payload: payload
        })
        .then(async (response) => {
          await Promise.all(this.additionalTaxSettings.map(async (item) => {
            if (item?.id) {
              this.$store.dispatch('customers/UPDATE', {
                resource: 'customers/business_activities',
                id: item.id,
                payload: {...item, ...{ customer: response.data.id }}
              })
            } else if (item.description.length) {
              this.$store.dispatch('customers/CREATE', {
                resource: 'customers/business_activities',
                payload: {...item, ...{ customer: response.data.id }}
              })
            }
          }))
          await Promise.all(this.additionalAddress.map(async (item) => {
            if (item?.id) {
              this.$store.dispatch('customers/UPDATE', {
                resource: 'customers/extra_addresses',
                id: item.id,
                payload: {...item, ...{ customer: response.data.id }}
              })
            } else if (item.address.length || item.state.length) {
              this.$store.dispatch('customers/CREATE', {
                resource: 'customers/extra_addresses',
                payload: {...item, ...{ customer: response.data.id }}
              })
            }
          }))
          await Promise.all(this.contacts.map(async (item) => {
            if (item?.id) {
              this.$store.dispatch('customers/UPDATE', {
                resource: 'customers/contacts',
                id: item.id,
                payload: {...item, ...{ customer: response.data.id }}
              })
            } else if (item.email.length || item.name.length || item.phone.length) {
              this.$store.dispatch('customers/CREATE', {
                resource: 'customers/contacts',
                payload: {...item, ...{ customer: response.data.id }}
              })
            }
          }))
          this.$emit('update:dialogCustomer', false)
          this.$emit('update')
          this.$dialog.message.info('El cliente ha sido actualizado')
        })
        .catch(() => {
          this.$dialog.message.error('Error al actualizar el cliente.')
        })
      }
    },
    validations: {
      editable: {
        name: {
          required,
          maxLength: maxLength(100)
        },
        state: {
          required
        },
        address: {
          required,
          maxLength: maxLength(64)
        },
        business_activity: {
          maxLength: maxLength(64)
        },
        taxpayer: {
          tax_id: {
            maxLength: maxLength(32)
          }
        },
        email: {
          email,
          maxLength: maxLength(254)
        },
        phone: {
          maxLength: maxLength(16)
        },
        website: {
          url
        }
      },
      additionalAddress: {
        $each: {
          address: {
          required,
            maxLength: maxLength(64)
          }
        }
      },
      contacts: {
        $each: {
          email: {
          required,
            email,
            maxLength: maxLength(64)
          }
        }
      }
    }
  }
  </script>